<template>
  <div class="h-full flex">
    <div class="w-1/2 md:hidden">
      <img
        src="@/assets/imgs/kv-signup.png"
        alt=""
        class="w-full h-full object-cover"
      />
    </div>
    <div
      class="w-1/2 bg-background flex items-center justify-center scrollbar-thin scrollbar-thumb-primary scrollbar-track-white overflow-y-scroll md:w-full"
    >
      <div class="px-8 py-10 m-auto">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup(props) {
    return {};
  },
};
</script>
